import { TextField } from "@material-ui/core";
import React from "react";
import { useState } from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  min-height: 100vh;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0rem 1.5rem;
  @media (min-width: 1024px) {
    padding-left: 2rem;
    padding-right: 2rem;
  }
`;

const InnerContainer = styled.div`
  @media (min-width: 640px) {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
  height: 2.5rem;
  margin-top: 0rem;
  margin-bottom: 2.5rem;
`;

const Heading = styled.h2`
  margin-top: 2.5rem;
  text-align: left;
  font-size: 1rem;
  @media (min-width: 640px) {
    font-size: 1.5rem;
  }
  font-weight: bold;
  line-height: 1.125;
  letter-spacing: -0.025em;
  color: #7f1d1d;
`;

const FormContainer = styled.div`
  @media (min-width: 640px) {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
`;

const Form = styled.form`
  > * + * {
    margin-top: 1.5rem;
  }
`;



const Button = styled.button`
  display: flex;
  width: 100%;
  justify-content: center;
  border-radius: 0.375rem;
  border: none;
  background-color: #dc2626;
  font-size: 0.875rem;
  padding: 0.375rem 0.75rem;
  font-weight: 600;
  line-height: 1.5rem;
  color: white;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0);
  &:hover {
    background-color: #ef4444;
  }
  &:focus {
    outline: 2px solid #dc2626;
  }
`;

export default function RemoveAccount() {
  const [email, setEmail] = useState("");
  const handleChange = (email) => {
    setEmail(email);
    console.log(email); // this will log the value of email whenever it changes.
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    console.log('email: ', email); // this will log the value of email when the form is submitted.
    
    // Send the email to the server to remove the account
  };
  return (
    <Container>
      <InnerContainer>
        <Heading>
          NOTE: if you remove your account, you will not be able to recover it
        </Heading>
      </InnerContainer>

      <FormContainer>
        <Form onSubmit={handleSubmit} action="#" method="POST">
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            style={{ direction: "ltr" }}
            label="Enter your email"
            name="email"
            value={email}
            onChange={(e)=>handleChange(e.target.value)}
            size={"small"}
          />

          <div>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              style={{ backgroundColor: "#ef4444", color: "#ffffff" }}
            >
              Delete Account
            </Button>
          </div>
        </Form>
      </FormContainer>
    </Container>
  );
}
