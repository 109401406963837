import React from "react";

function Terms() {
  React.useEffect(() => {
    document.title = "Terms";
  }, []);

  return (
    <div
      style={{
        fontFamily: '"Helvetica Neue" ,Helvetica, Arial, "sans-serif"',
        padding: "2em",
      }}
    >
      <div>
        <div>
          <h2>Tafseer Terms & Conditions</h2>
        </div>

        <hr />
        <article>
          <div>
            <p>
              <strong>Introduction</strong>
            </p>
            <p>
              Welcome to Tafseer, a product of Ahmad Alsobhi ( &quot;we&quot;,
              &quot;our&quot;, &quot;us&quot;)! By using our App, you agree to
              these Terms & Conditions (the &quot;Terms&quot;).
            </p>
            <p>
              <strong>Acceptance of Terms</strong>
            </p>
            <p>
              By accessing and using Tafseer, you accept and agree to be bound
              by these Terms. If you disagree with any part of the Terms, you
              may not access the App.
            </p>
            <p>
              <strong>User&#x27;s Responsibilities</strong>
            </p>
            <p>
              You are responsible for the content that you create via Tafseer.
              You must ensure the content adheres and complies with all
              applicable local, state, national, or international laws.
            </p>
            <p>
              <strong>Privacy Policy</strong>
            </p>
            <p>
              Please refer to our Privacy Policy for information on how we
              collect, use, and disclose the information we collect from you.
            </p>
            <p>
              <strong>Subscriptions and in-app purchases</strong>
            </p>
            <p>
              We offer several options for in-app purchases and subscriptions,
              including weekly,monthly,and yearly subscription. The user must
              have a valid ID account for financial procedures to be able to
              obtain the purchase service.
            </p>
            <p>
              <strong>Auto Renew</strong>
            </p>
            <p>
              Subscriptions are currently renewing. The amount required for
              renewal is automatically collected by receiving it.
            </p>
            <p>
              <strong>Prices</strong>
            </p>
            <p>
              Prices for products and in-app purchases vary according to the
              required options and features. Information about pricing can be
              found within the app before purchasing.
            </p>
            <p>
              <strong>Refund</strong>
            </p>
            <p>
              Refund your money after returning a refund request to the Apple
              Store.
            </p>
            <p>
              <strong>Cancellation Policy</strong>
            </p>
            <p>
              If you wish to cancel your subscription to our services, you must
              follow the respective cancellation procedures specified by Apple
              and Google.
            </p>
            <p>
              <strong>Copyright and Intellectual Property</strong>
            </p>
            <p>
              The App, including its graphics, logos, designs, text, sounds, and
              all other data, is owned by Ahmad Alsobhi or its licensors and is
              protected by copyright laws.
            </p>
            <p>
              <strong>Grant of License</strong>
            </p>
            <p>
              Tafseer grants you a personal, non-exclusive, non-transferable
              license to use the App on any device that you own or control.
            </p>
            <p>
              <strong>Termination</strong>
            </p>
            <p>
              We reserve the right to terminate your access to our App without
              any prior notice for violation of these Terms.
            </p>
            <p>
              <strong>Governing Law</strong>
            </p>
            <p>
              These Terms and any dispute concerning, relating, or referring to
              the Terms shall be governed by the laws of the country where us is
              registered.
            </p>
            <p>
              <strong>Changes to the Terms & Conditions</strong>
            </p>
            <p>
              We reserve the right, at our sole discretion, to modify or replace
              these Terms at any time.
            </p>
            <p>
              <strong>Contact Information</strong>
            </p>
            <p>
              If you have any questions or concerns regarding these Terms of
              Service, please contact us at
              <a
                style={{ "padding-left": "10px" }}
                href="mailto:info@explicationyourdream.com"
              >
                info@explicationyourdream.com{" "}
              </a>
              .
            </p>
          </div>
        </article>
      </div>
    </div>
  );
}

export default Terms;
