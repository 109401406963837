import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import FormNotifcations from "./pages/FormNotifcations";
import FormResetPassword from "./pages/FormResetPassword";
import ShareApp from "./pages/ShareApp";
import SuccessResetPassword from "./pages/SuccessResetPassword";
import SuccessAddDream from "./pages/SuccessAddDream";
import Promo from "./pages/Promo";
import Privacy from "./pages/Privacy";
import RemoveAccount from "./pages/RemoveAccount";
// import AddDream from "./pages/Text";
import AddDream from "./pages/AddDream";
import List from "./pages/List";
// import EditDream from "./pages/EditDream";
import NoonPayment from "./pages/NoonPayment";
import ConsultantsList from "./pages/ConsultantsList";
import Dashboard from "./pages/Dashboard";
import ButtonAppBar from "./components/ButtonAppBar";
import { useTranslation } from "react-i18next";
import withRoot from "./withRoot";
import { useTheme } from "@material-ui/core/styles";
import { ContextProvider } from "./context/ContextProvider";
// import { Context } from "./context/ContextProvider";
import Home from "./pages/Home";
import Orders from "./components/Orders";
import OrdersForUser from "./components/OrdersForUser";
import OneQuestion from "./components/OneQuestion";
import Item from "./components/Item";
import Terms from "./pages/Terms";
// import instance from "./api/api";


function App() {
  const theme = useTheme();

  const { t, i18n } = useTranslation();
  // const {isLoading } = useContext(Context);



  React.useEffect(() => {
    // if (isLoading) {
    //   // console.log("admin ss: ", isAdmin);
    // }
    i18n.changeLanguage("ar");
    document.body.dir = i18n.dir();
    theme.direction = i18n.dir();
  }, [theme, i18n]);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    document.body.dir = i18n.dir();
    theme.direction = i18n.dir();
  };

  // const reload = () => window.location.reload();

  return (
    <ContextProvider>
      <BrowserRouter>
        <ButtonAppBar t={t} i18n={i18n} changeLanguage={changeLanguage} />
        <div className="container mt-2" style={{ marginTop: 20 }}>
          <Switch>
            <Route exact path="/">
              <Home t={t} i18n={i18n} />
            </Route>
            <Route path="/share-app/:user_id/:id">
              <ShareApp t={t} i18n={i18n} />
            </Route>
            <Route path="/form-notifications" overflow="hidden">
              <FormNotifcations />
            </Route>
            <Route path={`/dashboard`}>
              <Dashboard />
            </Route>
            <Route path="/orders" overflow="hidden">
              <Orders />
            </Route>
            <Route path="/onequestion/:_id" overflow="hidden">
              <OneQuestion />
            </Route>
            <Route path={`/reset-password/:token`}>
              <FormResetPassword />
            </Route>
            <Route path={`/get-question-for-user/:user_id`}>
              <OrdersForUser />
            </Route>
            <Route path={`/successResetPassword`}>
              <SuccessResetPassword />
            </Route>
            <Route path={`/SuccessAddDream`}>
              <SuccessAddDream />
            </Route>
            <Route path={`/promo-dreams-live`}>
              <Promo />
            </Route>
            <Route path={`/privacy`}>
              <Privacy />
            </Route>
            <Route path={`/remove-account`}>
              <RemoveAccount />
            </Route>
            <Route path={`/terms`}>
              <Terms />
            </Route>
            <Route path="/add-dream">
              <AddDream t={t} />
            </Route>
            {/* <Route path="/edit-dream">
              <EditDream />
            </Route> */}
            <Route path="/NoonPayment">
              <NoonPayment />
            </Route>
            <Route path="/consultants-list">
              <ConsultantsList />
            </Route>
            <Route path="/list">
              <List />
            </Route>

            {/* <Route path={`/loading`}>
              {isAdmin ? <Loading /> : <Redirect to="/dashboard" />}
            </Route> */}
            <Route path={`/item`}>
              <Item />
            </Route>
          </Switch>
        </div>
      </BrowserRouter>
    </ContextProvider>
  );
}

export default withRoot(App);
